import styled, { css } from "styled-components"
import media from "@styles/media"

export const ArticleContainer = styled.div`
  display: flex;
  column-gap: 3rem;
  position: relative;

  ${props =>
    !props.large &&
    css`
      max-width: 850px;
      margin: 0 auto;
    `}

  ${media.md`
    column-gap: 0;
    display: block;
  `}

  ul {
    margin-bottom: 0;
  }
`

export const Sticky = styled.div`
  position: sticky;
  top: 6rem;
  height: min-content;
  padding-bottom: ${props => (props.padding ? props.padding : "1rem")};
`
