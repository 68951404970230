import React from "react"
import Flex from "@components/elements/flex"
import { graphql, useStaticQuery } from "gatsby"
import Paragraph from "@components/elements/paragraph"
import Heading from "@components/elements/heading"
import Image from "@components/image"
import Div from "@components/elements/div"
import styled, { useTheme } from "styled-components"
import SideBar from "@components/article/sidebar"
import { ArticleContainer } from "@components/common/article"
import Html from "@components/html"
import { LowGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { processImages } from "@utils/process-images"
import Integration, { IntegrationGrid } from "@components/integration"
import PropTypes from "prop-types"

const StyledDataFeed = styled(Flex)`
  border-left: 1px solid #ddd;
  padding-left: 3.75rem;
  h3 {
    position: relative;
    ::before {
      position: absolute;
      content: "";
      width: 3rem;
      left: -3.75rem;
      top: 50%;
      height: 1px;
      background: #ddd;
    }
  }
`

const LINK_SIDEBAR = [
  { id: "featured-data-warehouse", title: "Data Warehouses" },
  { id: "semantic-layers", title: "Semantic Layers" },
  {
    id: "data-feeds",
    title: "Data Feeds",
    children: {
      1: { id: "service-apis", title: "Services & APIs" },
      2: { id: "spreadsheets-files", title: "Spreadsheets & File Services" },
    },
  },
]

const FeaturedCategory = ({ category }) => {
  const services = category.services

  return (
    <Flex gap="1.5rem">
      <Heading as="h2" fontSize="1.5rem" id={category.html_id}>
        {category.name}
      </Heading>
      <Html html={category.description} parseElements />
      {services && (
        <IntegrationGrid>
          {services
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((service, index) => {
              return (
                <Integration
                  {...service}
                  key={`category-${category.html_id}-${index}`}
                />
              )
            })}
        </IntegrationGrid>
      )}
    </Flex>
  )
}

FeaturedCategory.propTypes = {
  category: PropTypes.object,
}

const DATA_FEEDS = {
  name: "Use Data Feeds to connect to diverse data sources",
  description:
    "<p>Connect to data sources (for example, spreadsheets, REST APIs, and databases) once and build one or more data feeds than can be used across multiple metrics.</p>",
  html_id: "data-feeds",
}

export const IntegrationsContent = () => {
  const { color } = useTheme()

  const { imageData, featuredCategories, integrationCategories } =
    useStaticQuery(graphql`
      query IntegrationsContent {
        imageData: allDirectusFiles(
          filter: {
            directus_id: {
              in: [
                "e304ad49-ca07-448f-85bf-6471a936d9f4"
                "5a458a10-7004-4e4b-8b74-8f1318feb577"
              ]
            }
          }
        ) {
          edges {
            node {
              directus_id
              cdn
              id
              placeholder
              title
            }
          }
        }
        featuredCategories: allIntegrationCategories(
          filter: {
            id: {
              in: [
                "a664bb00-0d9f-509c-ba23-e5117ebcec57"
                "32b023d1-1532-541b-97fc-473fc17bc9d5"
              ]
            }
          }
          sort: { fields: sort, order: DESC }
        ) {
          edges {
            node {
              id
              directus_id
              name
              description
              html_id
              services {
                status
                icon {
                  cdn
                  title
                }
                name
                link
              }
            }
          }
        }
        integrationCategories: allIntegrationCategories(
          filter: {
            id: {
              nin: [
                "a664bb00-0d9f-509c-ba23-e5117ebcec57"
                "32b023d1-1532-541b-97fc-473fc17bc9d5"
              ]
            }
          }
          sort: { fields: sort, order: ASC }
        ) {
          edges {
            node {
              directus_id
              name
              description
              html_id
              services {
                status
                icon {
                  cdn
                  title
                }
                name
                link
              }
            }
          }
        }
      }
    `)

  const images = processImages(imageData.edges)
  const categories = integrationCategories.edges
  const { gradient: gradientBg } = useGradient()

  return (
    <>
      <Div
        position="relative"
        overflow="hidden"
        background={color.indigo700}
        padding="4rem 0 6rem"
        margin="0 0 4rem"
      >
        <LowGradient src={gradientBg.cdn} loading="lazy" />
        <Div textContainer margin="6rem auto 4rem">
          <Heading
            color={color.indigo200}
            center
            fontSize="2.4rem"
            margin="0 0 1.5rem"
          >
            Works with any data stack
          </Heading>
          <Paragraph center lineHeight={1.5} fontSize="1.2rem" color="white">
            PowerMetrics intergrates with any data source and supports business
            users with metric visualization and analysis with powerful analytic
            capabilities - or integrates with your BI tool or data app.
            Regardless of the solution chosen, metrics can be used
            interchangeably with other metrics in calculations, analysis and
            visualizations.
          </Paragraph>
        </Div>
        <Div container>
          <Image eager file={images["5a458a10-7004-4e4b-8b74-8f1318feb577"]} />
        </Div>
      </Div>

      <Div container>
        <ArticleContainer large>
          <SideBar
            simplified
            cta={false}
            message={`Categories`}
            manualList={LINK_SIDEBAR}
          />
          <Flex margin="0 auto 6rem" gap="5rem">
            {featuredCategories.edges.map(({ node: category }, i) => (
              <FeaturedCategory category={category} key={`category-${i}`} />
            ))}
            <FeaturedCategory category={DATA_FEEDS} key={`data-feeds`} />
            <StyledDataFeed gap="5rem">
              {categories.map(({ node: category }, categoryI) => (
                <Flex gap="2rem" key={`integration-${categoryI}`}>
                  <Flex gap="1.5rem">
                    <Heading as="h3" fontSize="1.5rem" id={category.html_id}>
                      {category.name}
                    </Heading>
                    <Html html={category.description} parseElements />
                  </Flex>
                  <IntegrationGrid maxSize="md">
                    {category.services
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((service, index) => {
                        return (
                          <Integration {...service} key={`service-${index}`} />
                        )
                      })}
                  </IntegrationGrid>
                </Flex>
              ))}
            </StyledDataFeed>
          </Flex>
        </ArticleContainer>
      </Div>
    </>
  )
}
