import React from "react"
import Span from "@components/elements/span"
import Button from "@components/elements/button"
import Div from "@components/elements/div"
import { useProduct } from "@hooks/use-product"
import { CtaContainer } from "@components/cta/colourful/styled"
import PropTypes from "prop-types"
import { Logo } from "@components/common/logo"
import { useFullCta } from "@hooks/use-cta"

const SquareColourfulCTA = ({ title, description }) => {
  const { product } = useProduct()
  const { cta } = useFullCta(title, description)

  return (
    <CtaContainer
      padding="2rem 1.25rem 2.5rem"
      product={product}
      minWidth="300px"
    >
      <Div zIndex={10} position="relative">
        <Logo style={{ margin: "0 auto 1rem" }} />
        <Span
          fontSize="0.9rem"
          fontWeight="600"
          display="block"
          color="white"
          center
        >
          {cta.title}
        </Span>
        <Span
          padding="0 0 2rem"
          lineHeight="1.3"
          fontSize="1.4rem"
          fontWeight="700"
          display="block"
          color="white"
          center
        >
          {cta.description}
        </Span>
        <Button.Auto
          data-cta-location="body"
          text
          arrow={false}
          klipsStyles={{
            noticeColor: "white",
          }}
        />
      </Div>
    </CtaContainer>
  )
}

SquareColourfulCTA.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SquareColourfulCTA
