import React from "react"
import Layout from "@components/layout"
import { IntegrationsContent } from "@components/pages/integrations/content"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"

const Integrations = () => {
  return (
    <Layout
      title={`PowerMetrics Integrations`}
      description={`PowerMetrics fits neatly into your data stack and integrates with all the data sources your teams work with every day.`}
      fullWidth
      marginless
      login
    >
      <IntegrationsContent />

      <PowerMetricsEOPCta heading={"Make analytics everybody's business."} />
    </Layout>
  )
}

export default Integrations
