import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Flex from "./elements/flex"
import Image from "./image"
import Paragraph from "./elements/paragraph"
import Grid from "./elements/grid"

const IntegrationCard = styled(Flex)`
  transition: box-shadow 0.3s;
  height: 100%;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.subtle};
  }
`

const Integration = ({ status, link, icon, name, fixed }) => {
  if (status !== "published") return

  const content = (
    <Flex padding="3rem 1rem" gap="2rem" width={fixed} height={fixed}>
      <Image
        style={{
          margin: "0 auto",
          maxWidth: "50px",
        }}
        file={icon}
      />
      {name && (
        <Paragraph center fontSize="1rem" fontWeight={600} lineHeight={1.5}>
          {name}
        </Paragraph>
      )}
    </Flex>
  )

  return link ? (
    <a target="_blank" rel="noreferrer" href={link}>
      <IntegrationCard
        borderRadius="1rem"
        alignSelf="stretch"
        justifyContent="center"
        border="1px solid #DDD"
      >
        {content}
      </IntegrationCard>
    </a>
  ) : (
    <Flex
      borderRadius="1rem"
      alignSelf="stretch"
      justifyContent="center"
      border="1px solid #DDD"
    >
      {content}
    </Flex>
  )
}

Integration.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  link: PropTypes.string,
  fixed: PropTypes.string,
}

export const IntegrationGrid = ({ children, maxSize }) => {
  let size
  if (maxSize === "sm") size = "repeat(2, 1fr)"
  if (maxSize === "md") size = "repeat(3, 1fr)"

  const columns = size ?? "repeat(4, 1fr)"
  const columnsMd = size ?? "repeat(3, 1fr)"
  const columnsSm = "repeat(2, 1fr)"

  return (
    <Grid
      columns={columns}
      columnsMd={columnsMd}
      columnsSm={columnsSm}
      margin="0"
      gap="1rem"
    >
      {children}
    </Grid>
  )
}
IntegrationGrid.propTypes = {
  children: PropTypes.node,
  maxSize: PropTypes.oneOf(["sm", "md"]),
}

export default Integration
